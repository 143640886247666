<template>
  <b-sidebar id="sidebar-send-to-customer" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('action.send') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- From -->
        <b-form-group label="From" label-for="from">
          <b-form-input id="from" v-model="sendQuotationData.from" trim type="email" />
        </b-form-group>

        <!-- To -->
        <b-form-group label="To" label-for="to">
          <b-form-input id="to" v-model="sendQuotationData.to" trim type="email" />
        </b-form-group>

        <!-- Subject -->
        <b-form-group label="Subject" label-for="subject">
          <b-form-input id="subject" v-model="sendQuotationData.subject" trim />
        </b-form-group>

        <!-- Message -->
        <b-form-group label="Message" label-for="message">
          <b-form-textarea id="message" v-model="sendQuotationData.message" rows="5" trim />
        </b-form-group>

        <!-- ? File/Attachement -->
        <b-badge variant="light-primary">
          <feather-icon icon="LinkIcon" />
          <span class="ml-50">{{ $t('invoice.attached_quotes') }}</span>
        </b-badge>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mr-2" @click="hide">
            <font-awesome-icon icon="ban" />
            Cancel
          </b-button>
          <b-button v-ripple.400 type="submit" @click="sendCustomerSuccess">
            <font-awesome-icon icon="paper-plane" />
            {{ $t('action.send') }}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: 'SendToCustomer',

  data() {
    return {
      sendQuotationData: {
        from: 'mfc@provider.com',
        to: 'don85@johnson.com',
        subject: '',
        message: '',
      },
    }
  },
  methods: {
    sendCustomerSuccess() {
      this.alert('Message send', 'The message has been send to don85@johnson.com')
      this.$router.push({ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } })
    },
  },
}
</script>

<style></style>
