<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row v-if="serviceData" class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <b-card-text class="mb-25">
                    {{ quotationData.transmitter.office }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ quotationData.transmitter.address }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    {{ quotationData.transmitter.phone }}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title mr-2">
                      {{ $t('common.quotation') | capitalize }}
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="invoice-data-id" v-model="serviceData.id" disabled />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> {{ $t('quotation.date_issued') | capitalize }}: </span>
                    <flat-pickr v-model="serviceData.issuedDate" class="form-control invoice-edit-input" />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> {{ $t('quotation.due_date') | capitalize }}: </span>
                    <flat-pickr v-model="serviceData.dueDate" class="form-control invoice-edit-input" />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">{{ $t('invoice.to') | capitalize }}: :</h6>

                  <!-- Selected Client -->
                  <div v-if="serviceData.client" class="mt-1">
                    <h6 class="mb-25">
                      {{ serviceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ serviceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25"> {{ serviceData.client.address }}, {{ serviceData.client.country }} </b-card-text>
                    <b-card-text class="mb-25">
                      {{ serviceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ serviceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col xl="6" cols="12" class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block">
                  <div>
                    <h6 class="mb-2">{{ $t('invoice.payment_details') | capitalize }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t('invoice.total_due') | capitalize }}:</td>
                          <td>
                            <span class="font-weight-bold">{{ servicesTotal }}{{ $store.state.appConfig.currency }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t('invoice.bank_name') | capitalize }}:</td>
                          <td>{{ serviceData.payment.bankName }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t('common.country') | capitalize }}:</td>
                          <td>{{ serviceData.payment.country }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t('quotation.payment.iban') }}:</td>
                          <td>{{ serviceData.payment.IBAN }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t('quotation.payment.swift') }}: :</td>
                          <td>{{ serviceData.payment.SWIFT }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-body class="mt-0 invoice-padding">
              <b-row v-if="$route.params.type === '1'" class="invoice-spacing mt-0">
                <!-- Col: FUEL CERTIFICATE To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-25">Fuel:</h6>
                  <b-card-text class="mb-25"> Aircraft : {{ fuelCertificate.aircraftRegistration }} </b-card-text>
                  <b-card-text class="mb-25"> Fuelling location : {{ fuelCertificate.location }} </b-card-text>
                  <b-card-text class="mb-0"> Provider : {{ fuelCertificate.provider }} </b-card-text>
                  <b-card-text class="mb-0"> Client : {{ fuelCertificate.client }} </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                <div v-if="serviceData.items">
                  <b-row v-for="(item, index) in serviceData.items" :key="index" ref="row" class="pb-2">
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="3">
                            {{ $t('quotation.service.description') }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ $route.params.type === '1' ? 'Unit ' + $t('common.excluding_taxes.abbreviation') : $t('common.excluding_taxes.abbreviation') }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ $t('common.vat') }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ $t('common.including_taxes.abbreviation') }}
                          </b-col>
                          <b-col cols="12" lg="1"> {{ $t('quotation.quantity_abbreviation') }}{{ $route.params.type === '1' ? '(usg)' : '' }} </b-col>
                          <b-col cols="12" lg="2">
                            {{ $t('quotation.excluding_taxes') }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="3">
                            <label class="d-inline d-lg-none">{{ $t('quotation.service.description') }}</label>
                            <v-select
                              v-model="item.itemTitle"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="itemsOptions"
                              label="itemTitle"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Item"
                              :disabled="$route.params.type === '1' && index === 0"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">{{ $t('common.excluding_taxes.abbreviation') }}</label>
                            <b-form-input v-model.number="item.HT" type="number" class="mb-2" :value="item.HT" />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">{{ $t('common.vat') }}</label>
                            <v-select
                              v-model.number="item.TVA"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="tvaOptions"
                              label="label"
                              :clearable="false"
                              class="item-selector-title"
                              :placeholder="$t('common.vat')"
                              @input="setServiceTVA($event, index)"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">{{ $t('common.including_taxes.abbreviation') }}</label>
                            <b-form-input
                              v-model.number="item.TTC"
                              type="number"
                              class="mb-2"
                              :input="calcTTC(index)"
                              :value="item.TTC"
                              @input="setServiceTTC($event, index)"
                            />
                          </b-col>
                          <b-col cols="12" lg="1">
                            <label class="d-inline d-lg-none">{{ $t('quotation.quantity_abbreviation') }}</label>
                            <b-form-input v-model.number="item.qty" type="number" class="mb-2" :disabled="$route.params.type === '1' && index === 0" />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">{{ $t('quotation.excluding_taxes') }}</label>
                            <p class="mb-1">{{ item.HT * item.qty }}{{ $store.state.appConfig.currency }}</p>
                          </b-col>
                          <b-col cols="12" lg="3">
                            <label class="d-inline d-lg-none">{{ $t('quotation.description') }}</label>
                            <b-form-textarea
                              v-model="item.description"
                              placeholder="Description"
                              class="mb-2 mb-lg-0"
                              :disabled="$route.params.type === '1' && index === 0"
                              no-resize
                            />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-datepicker
                              v-model="item.date"
                              placeholder="Date"
                              class="mb-1 form-control"
                              :disabled="$route.params.type === '1' && index === 0"
                              :locale="$i18n.locale"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-timepicker
                              v-model="item.time"
                              :placeholder="$t('common.hour') | capitalize"
                              :disabled="$route.params.type === '1' && index === 0"
                            />
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            v-b-tooltip.hover.bottom
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            title="Delete service"
                            @click="modalDeleteService(index)"
                          />
                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            v-b-tooltip.hover.bottom
                            size="16"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                            title="Extra settings"
                          />

                          <!-- Setting Item Form -->
                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <!-- Field: Discount -->
                                <b-col cols="12">
                                  <b-form-group label="Discount(%)" :label-for="`setting-item-${index}-discount`">
                                    <b-form-input :id="`setting-item-${index}-discount`" v-model.number="item.discount" type="number" :value="null" />
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12" class="d-flex justify-content-between mt-1">
                                  <b-button
                                    variant="outline-primary"
                                    @click="
                                      () => {
                                        $refs[`form-item-settings-popover-${index}`][0].$emit('close')
                                      }
                                    "
                                  >
                                    Apply
                                  </b-button>
                                  <b-button
                                    variant="outline-secondary"
                                    @click="
                                      () => {
                                        $refs[`form-item-settings-popover-${index}`][0].$emit('close')
                                      }
                                    "
                                  >
                                    Cancel
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <b-button v-ripple.400 size="sm" @click="addNewItemInItemForm">
                  {{ $t('service.action.add') }}
                </b-button>
              </div>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body v-if="serviceData.items.length" class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-md="1">
                  <label class="text-nowrap mr-50 font-weight-bold">{{ $t('quotation.sales_person') }}:</label>
                  <v-select v-model="contactSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="title" :options="contactOptions" />
                </b-col>

                <!-- Col: Calc total -->
                <b-col cols="12" md="6" class="mt-md-3 d-flex justify-content-end" order="1" order-md="2">
                  <quotation-bill :services="serviceData.items" @servicesTotal="setInvoiceTotal" />
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold"> {{ $t('quotation.note') }} : </span>
              <b-form-textarea v-model="serviceData.note" placeholder="note" />
            </b-card-body>
            <b-card-body v-if="$route.params.type === '1'" class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center mb-2" order="2" order-md="1">
                  <label class="text-nowrap mr-50 font-weight-bold">{{ $t('quotation.contact_uplift') }}:</label>
                  <v-select
                    v-model="contactUpliftSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="title"
                    :options="contactUpliftOptions"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Send Quotation -->
          <b-button v-ripple.400 v-b-toggle.sidebar-send-to-customer class="mb-5" block>
            <font-awesome-icon class="mr-50" icon="paper-plane" />
            {{ $t('quotation.action.send_customer') }}
          </b-button>

          <!-- Button: Download -->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block>
            <font-awesome-icon class="mr-50" icon="eye" />
            {{ $t('action.preview') }}
          </b-button>

          <!-- Button: Save -->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block @click="showToast('success')">
            <font-awesome-icon class="mr-50" icon="save" />
            {{ $t('action.save') }}
          </b-button>

          <!-- Button: Add Payment -->
          <b-button v-b-toggle.sidebar-method-payment v-ripple.400 variant="outline-primary" class="mb-75" block>
            <font-awesome-icon class="mr-50" icon="credit-card" />
            {{ $t('quotation.payment.method') }}
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group label="Accept Payment Via" label-for="payment-method">
            <v-select
              v-model="serviceData.paymentMethod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>
          <!-- Payment Terms -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">
              {{ $t('quotation.payment.terms') }}
            </label>
            <b-form-checkbox id="patymentTerms" :checked="true" switch />
          </div>

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">
              {{ $t('quotation.client_notes') }}
            </label>
            <b-form-checkbox id="clientNotes" :checked="true" switch />
          </div>
          <b-form-group>
            <v-select
              v-model="serviceData.cancellationPolicy"
              placeholder="Cancellation policy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cancellationPolicyOptions"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- Cancellation policy desc  -->
          <div class="d-flex justify-content-center p-1 bg-light-primary rounded">
            <div>
              <h5 class="text-center py-1">
                {{ $tc('cancellation_policy.title', 1) }}
              </h5>
              <ul class="text-body">
                <li v-for="(item, index) in cancellationPolicy.desc" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <sidebar-send-to-customer />
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import flatPickr from 'vue-flatpickr-component'
import Logo from '@core/layouts/components/Logo.vue'
import QuotationBill from '@/views/quotation/component/QuotationBill.vue'
import SidebarSendToCustomer from './sidebar/SendToCustomer.vue'

export default {
  name: 'QuotationForm',

  components: {
    flatPickr,
    Logo,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    SidebarSendToCustomer,
    QuotationBill,
  },

  mixins: [heightTransition],
  props: {
    services: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      showToast(variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'CheckCircleIcon',
            text: 'Your quotation has been saved',
            variant,
          },
        })
      },
      modalDeleteService(index) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete this service.', {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('common.yes'),
            cancelTitle: this.$t('common.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.removeItem(index)
            }
          })
      },
      quotationData: {
        id: 4987,
        issuedDate: '13 Dec 2019',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson',
        },
        transmitter: {
          office: 'Office 149, 450 South Brand Brooklyn',
          address: 'San Diego County, CA 91905, USA',
          phone: '+1 (123) 456 7891',
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        dueDate: '23 Apr 2019',
      },
      servicesTotal: 0,
      dateIssued: '',
      dueDate: '',
      contactSelected: [{ title: 'Brian' }],
      fuelCertificate: {
        certificateNumber: '#1234abc',
        certificateDate: '2021-07-08',
        startFuelling: '08:00',
        endFuelling: '09:00',
        aircraftRegistration: 'Dassauft falcon 2000 (FA 2000)',
        fuelTruckNumber: '#bzefezofuh796',
        fuelType: 'JET A1',
        fuelAmount: 350,
        fuelDensity: 34,
        fuelTemperature: 35,
        fuelNet: 12,
        fuelTax: 6,
        invoiceNumber: '#08YBJ67ZH',
        provider: 'Oil companies',
        client: 'Client 1',
        location: 'Amsterdam',
        ticketNumber: 100,
        qtyType: 'gallons',
      },
      contactOptions: [{ title: 'Denis' }, { title: 'Brian' }],
      contactUpliftSelected: [{ title: 'Christian' }],
      contactUpliftOptions: [{ title: 'Robert' }, { title: 'Christian' }],
      cancellationPolicyOptions: [
        {
          label: 'Modèle 1',
          desc: ['Si le client annule 24 heures avant le voyage alors 50% de pénalité', 'Si le client reporte 24 heures avant le voyage alors pas de pénalité'],
        },
        {
          label: 'Modèle 2',
          desc: ['Si le client annule 24 heures avant le voyage alors 25% de pénalité', 'Si le client reporte 72 heures avant le voyage alors pas de pénalité'],
        },
      ],
      cancellationPolicy: {
        label: 'Modèle 1',
        desc: ['Si le client annule 24 heures avant le voyage alors 50% de pénalité', 'Si le client reporte 24 heures avant le voyage alors pas de pénalité'],
      },
      serviceData: {
        id: 4987,
        issuedDate: '13 Dec 2019',
        cancellationPolicy: {
          label: 'Modèle 1',
          desc: ['Si le client annule 24 heures avant le voyage alors 50% de pénalité', 'Si le client reporte 24 heures avant le voyage alors pas de pénalité'],
        },
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson',
        },
        payment: {
          SWIFT: 'SWIFT234KOFZIPJ',
          IBAN: 'IBAN293809HFOE',
          bankName: 'American Bank',
          country: 'United states',
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        balance: '$724',
        dueDate: '23 Apr 2019',
        items: [
          {
            itemTitle: 'Service 1',
            HT: 0,
            TTC: 0,
            qty: 1,
            TVA: 10,
            discount: 0,
            description: '',
            date: '',
            time: '',
          },
        ],
        note: 'Note',
        paymentMethod: 'Bank Account',
      },
      paymentDetails: {
        totalDue: '$12,110.55',
        bankName: 'American Bank',
        country: 'United States',
        iban: 'ETD95476213874685',
        swiftCode: 'BR91905',
      },
      itemFormBlankItem: {
        itemTitle: 'Service 1',
        HT: 0,
        TTC: 0,
        qty: 1,
        TVA: 10,
        discount: 0,
        description: '',
        date: '',
        time: '',
      },
      itemsOptions: [
        {
          itemTitle: 'Service 1',
          HT: 0,
          TTC: 0,
          qty: 1,
          tva: 10,
          description: 'Description service.',
          date: '',
        },
        {
          itemTitle: 'Service 2',
          HT: 0,
          TTC: 0,
          qty: 1,
          tva: 10,
          description: 'Description service.',
          date: '',
        },
      ],
      tvaOptions: [
        {
          label: '10%',
          value: 10,
        },
        {
          label: '20%',
          value: 20,
        },
      ],
      paymentMethods: ['Bank Account', 'PayPal', 'UPI Transfer'],
    }
  },
  computed: {
    servicesTotalTVA() {
      const totalTTC = this.serviceData.items.map(item => item.TTC).reduce((prev, next) => prev + next)
      return this.precisionRound(totalTTC - this.servicesSubtotalHT, 2)
    },
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'serviceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
    if (this.$route.params.type === '1') {
      this.serviceData.items[0].itemTitle = 'Fuel'
      this.serviceData.items[0].qty = 26
      this.serviceData.items[0].date = this.fuelCertificate.certificateDate
      this.serviceData.items[0].time = this.fuelCertificate.startFuelling
      this.serviceData.items[0].itemTitle = `${this.fuelCertificate.fuelType} | 98.42 ltr`
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    if (this.$route.params.services) {
      this.serviceData = this.$route.params.services
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    setInvoiceTotal(val) {
      this.servicesTotal = val
    },
    precisionRound(number, precision) {
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(10, precision)
      return Math.round(number * factor) / factor
    },
    setServiceTVA(value, index) {
      this.serviceData.items[index].TVA = value.value
    },
    setServiceTTC(value, index) {
      return value - value * this.serviceData.items[index].qty * (this.serviceData.items[index].TVA / 100)
    },
    calcTTC(index) {
      // Fix js calculator Ex: 100 * 1,1 = 100,000000001
      this.serviceData.items[index].TTC = this.precisionRound(
        this.serviceData.items[index].HT * this.serviceData.items[index].qty * (this.serviceData.items[index].TVA / 100 + 1),
        2
      )
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.serviceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.serviceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
